// extracted by mini-css-extract-plugin
export var desktopBg = "showcasepage-module--desktopBg--9Dq0Y";
export var desktopScreenshot = "showcasepage-module--desktopScreenshot--ZSnPl";
export var desktopScreenshots = "showcasepage-module--desktopScreenshots--4rhZU";
export var desktopScreenshotsCon = "showcasepage-module--desktopScreenshotsCon--Kr7aF";
export var highlight = "showcasepage-module--highlight--kBE2C";
export var introCon = "showcasepage-module--introCon--NezDi";
export var introText = "showcasepage-module--introText--tPTa1";
export var introTextContin = "showcasepage-module--introTextContin--fSHap";
export var logo = "showcasepage-module--logo--qQLyU";
export var logoCon = "showcasepage-module--logoCon--Z1kIG";